import React from "react"
import { PageWrapper } from "~components"
import HeaderButton from '~sections/project/Header'
import CtaSection from "~sections/marketing/Cta"
import FooterOne from "~sections/marketing/FooterOne"
import { Helmet } from "react-helmet";
import CoxWidget from "~sections/featured-story/components/CS-widget/cox"
//import scrollTo from 'gatsby-plugin-smoothscroll';
const header = {
  headerClasses: "site-header site-header--menu-start light-header site-header--sticky",
  containerFluid:false,
  buttonBlock: (
    <HeaderButton
      className="ms-auto d-none d-xs-inline-flex"
      btnOneText="Login"
      btnTwoText="14 day free trial"
      mr="15px"
      mrLG="0"
    />
  ),
}
const PageCmp = ({ data }) => {
  
  useEffect(() => {
      window.scrollTo(0,0)
  }, [data])
  
}
export default function Cox() {
  return (
    <>
    <Helmet>
    <title>Cox Marketing Agency Leverages We-Connect To Help Business Owners Overcome Prospecting Challenges</title>
    <meta name="description" content="Cox Marketing Agency helps business owners capture, nurture, and close more deals with help from We-Connect. Learn what the founder and CEO thinks."/>
    <meta property="og:title" content="Cox Marketing Agency Leverages We-Connect To Help Business Owners Overcome Prospecting Challenges"/>
    <meta property="og:description" content="Cox Marketing Agency helps business owners capture, nurture, and close more deals with help from We-Connect. Learn what the founder and CEO thinks. "/>
    <meta property="og:type" content="website"/>
    <meta property="og:url" content="https://we-connect.io/cox-marketing-agency"/>
    <meta property="twitter:card" content="summary_large_image"/><meta property="twitter:site" content="@WeConnect_io"/>
    <meta property="twitter:title" content="Cox Marketing Agency Leverages We-Connect To Help Business Owners Overcome Prospecting Challenges"/>
    <meta property="twitter:description" content="Cox Marketing Agency helps business owners capture, nurture, and close more deals with help from We-Connect. Learn what the founder and CEO thinks. "/>
    <meta property="twitter:image" content="https://we-connect.io/cox-marketing-agency-leverages-we-connect.png"/>
    </Helmet>
    <PageWrapper headerConfig={header}>
      <CoxWidget />
      <CtaSection />
      <FooterOne/>
    </PageWrapper>
    </>
  )
}