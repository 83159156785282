import React, { useState, useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Widget from './style'
import img from "~assets/image/customer-stories/marketing-agency.jpg"
import { SuperTag, Link } from '~components';

export default function CoxWidget(){
  
return(
      
<Widget>
  <Container>
  <Widget.Block>
    <Row className=" justify-content-lg-start">
    <Col xs="12" className="col-xl-6 col-lg-6 col-md-8 col-xs-10">
      <Widget.Box>
        <Link to="/customer-stories"><Widget.MiniHeading><Widget.BackButton textColor="#000"><i class='fas fa-angle-left'></i>  Customer Stories</Widget.BackButton> </Widget.MiniHeading></Link>
          <Widget.Heading as="h2"> <SuperTag value="How Cox Marketing Agency Leverages We-Connect To Help Business Owners Overcome Prospecting Challenges"/> </Widget.Heading>
          
        </Widget.Box>
      </Col>
    <Col className="col-xl-6 col-lg-6 col-md-8 col-xs-10">
    <Widget.Image>
            <img  src={img} alt="content" layout="fullWidth" className="w-100" placeholder="blurred" />
        </Widget.Image>
      </Col>
    </Row>
    </Widget.Block>

    <Widget.Block>
    <Row className="justify-content-lg-start">
    <Col xs="12" className="col-xl-4 col-lg-4 col-md-8 col-xs-10">
        <Widget.Card>
        <Widget.Company as="h2">Cox Marketing Agency</Widget.Company>
        <Widget.Text>Based in Miami, Cox Marketing Agency excels in crafting customized strategies to enhance visibility and build robust sales pipelines. The agency specializes in assisting business owners who face challenges in prospecting on LinkedIn, particularly in connecting with decision-makers. Cox Marketing Agency provides solutions to alleviate the burden of reaching out to individuals daily on LinkedIn. Their unique "C.N.C. formula" focuses on Capturing, Nurturing, and Closing more sales, offering an effective approach for their clients.</Widget.Text>
        </Widget.Card>

        <Widget.Card>
        <Widget.SubPara> <b>Industry</b> </Widget.SubPara>
        <Widget.Text>Marketing</Widget.Text>
        </Widget.Card>

        <Widget.Card>
        <Widget.SubPara> <b>Company Size</b> </Widget.SubPara>
        <Widget.Text>5</Widget.Text>
        </Widget.Card>

        <Widget.Card>
        <Widget.SubPara> <b>Features Used</b> </Widget.SubPara>
        <Widget.Text>Smart sequences</Widget.Text>
        </Widget.Card>
      </Col>
    <Col className="col-xl-6 col-lg-6 col-md-8 col-xs-10">

        <Widget.Title as="h2"><b>A First-Person Perspective:</b></Widget.Title>
        <Widget.Text>I'm Yulianna Cox, CEO and Founder of Cox Marketing Agency, based in the heart of Miami. My agency is dedicated to creating tailored strategies that not only boost visibility but also build strong sales pipelines. We specialize in helping business owners overcome the challenges of prospecting on LinkedIn, especially those struggling to connect with decision-makers. At Cox Marketing Agency, we've developed a unique approach, the "C.N.C. formula," which focuses on Capturing, Nurturing, and Closing more sales. This formula has been a cornerstone in alleviating the daily grind of manual outreach on LinkedIn for our clients.</Widget.Text>
        <Widget.Text>In our industry, the real game-changer has been We-Connect. Its ability to maximize outreach potential for business owners like me, facing hurdles with LinkedIn prospecting, has been invaluable. Before discovering We-Connect, I was swamped with the manual task of sending out two to three LinkedIn messages a day. It was a real struggle, feeling more like a numbers game, particularly when I aimed to reach key decision-makers.
        </Widget.Text>
        <Widget.Text>
        Balancing my role as a mother of three alongside my professional responsibilities, I was in dire need of a time-saving solution that wouldn't compromise the safety of my account. That's where We-Connect came into play. Its user-friendliness and intuitive design were exactly what I, and many business owners, needed for efficient and effective outreach.
        </Widget.Text>
        <Widget.Text>
        My journey with We-Connect began with revamping my LinkedIn profile and fine-tuning my messaging scripts to resonate with decision-makers. This preparation made it surprisingly easy to upload contacts and start my campaign.
        </Widget.Text>
        <Widget.Text>My method is distinct because it doesn't push sales aggressively at the outset. I focus on establishing a human-to-human connection first, laying the foundation of trust with decision-makers. The sales pitch is introduced later, once a rapport has been established.</Widget.Text>
        <Widget.Text>It's been a gratifying journey to see many clients return to me months down the line, emphasizing the relationship-based and non-linear nature of our conversion process.</Widget.Text>
        <Widget.Text>In summary, my experience with We-Connect has been nothing short of transformative. Manual outreach, especially when targeting decision-makers, could have been overwhelming. But with We-Connect, the entire process is streamlined, allowing me to concentrate more on what I love most – serving our clients effectively and passionately.</Widget.Text>

        <Widget.CardTwo>
        <Widget.SubPara> Start your free trial and start kicking butt like this company did.</Widget.SubPara>
        <Widget.Button target="_blank" to="//account.we-connect.io/signup" className="btn btn-primary text-white">14 day free trial</Widget.Button>
        </Widget.CardTwo>
      </Col>
    </Row>
    </Widget.Block>
    
  </Container>
</Widget>

)
}